
import React from "react";
import './Loader.css'
export default function Loader(props) {
    return (
        <div className="loader"> 
        <div className="cm-spinner"></div>
        </div>
    );
}

